import React, { useState, useEffect } from "react";
import "./DynamicText.css"; // Importa il file CSS

const DynamicText = () => {
  const text = "ingegneri"; // La parola su cui applicare l'animazione

  return (
    <h1>
      Siamo un gruppo di{" "}
      <span>
        {text.split("").map((letter, index) => (
          <span
            key={index}
            className="wave-letter"
            style={{ animationDelay: `${index * 0.1}s` }}
          >
            {letter}
          </span>
        ))}
      </span>
      , con competenze in automazione, elettronica e sviluppo software.
    </h1>
  );
};

export default DynamicText;
