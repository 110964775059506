import React from "react";
import { FaInstagram, FaLinkedin, FaFacebook } from "react-icons/fa"; // Importa le icone
import "./Footer.css";
const Footer = () => {
  return (
    <>
      <div className="footer-container">
        {/* Prima riga */}
        <div className="footer-top">
          <div className="footer-intro">
            <h2>Osa cambiare. Digitalizza la tua azienda</h2>
            <p>
              Entra in contatto con noi, fissa una call o scrivici una mail.
            </p>
            {/* <Button
          text="Co"
          className="btn-primary navbar-btn"
          href="/contatti"
        /> */}
          </div>
        </div>

        {/* Seconda riga */}
        <div className="footer-links">
          <div>
            <h3>Scopri di più su di noi</h3>
            <a href="chi-siamo">About us</a>
            <a href="lavora-con-noi">Lavora con noi - Carriere</a>
          </div>
          <div>
            <h3>Casi Studio</h3>
            <a href="/casi-studio">Visualizza Casi Studio</a>
          </div>
          <div>
            <h3>Blog</h3>
            <a href="/blog">Leggi le ultime News</a>
          </div>
        </div>
      </div>

      {/* Componente sottostante */}
      <div className="footer-bottom">
        <div className="footer-logo">
          <img src="/logo.png" alt="Logo" />
        </div>
        <div className="footer-copyright">
          <p>&copy; 2024 Tutti i diritti riservati. Gemini Automation.</p>
        </div>
        <div className="footer-social">
          <a href="#">
            <FaInstagram size={24} />
          </a>
          <a href="#">
            <FaLinkedin size={24} />
          </a>
          <a href="#">
            <FaFacebook size={24} />
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
