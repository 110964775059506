import React, { useEffect, useState, useRef } from "react";
import "./HomePage.css";
import Notification from "../../components/UI/Notification/Notification";
import { FaRegClock } from "react-icons/fa";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import ServiceCarousel from "../../components/ServiceCarousel";
import TimeLine from "../../components/TimeLine/TimeLine";
import Animation from "../../components/VideoAnimazione/Animation";
import TitleOptions2 from "../../components/TitleOptions2/TitleOptions2";
import { color } from "framer-motion";
import Photo from "../../components/Photo/Photo";
import MagnifyingGlass from "../../components/Services/MagnifyingGlass";
import DynamicText from "./DynamicText";

import ThreeDModel from "../../components/3d/3d";

const HomePage = () => {
  const [showIntro, setShowIntro] = useState(true);
  const videoRef = useRef(null);

  useEffect(() => {
    const handleVideoEnd = () => {
      setShowIntro(false);
    };

    const video = videoRef.current;
    if (video) {
      video.addEventListener("ended", handleVideoEnd);
    }

    return () => {
      if (video) {
        video.removeEventListener("ended", handleVideoEnd);
      }
    };
  }, []);
  const [isIntroScrolled, setIsIntroScrolled] = useState(false);
  const items = [
    {
      title: "Hardware raccolta dati dal PLC",
      description:
        "Forniamo i dispositivi hardware per interfacciarci con i Vostri PLC, raccogliendo i dati e analizzando i parametri di produzione. Con un'ampia gamma di possibilità, rendiamo questa fase facile ed accessibile, riducendo la complessità di installazione",
      icon: "🔌",
    },
    {
      title: "Manutenzione Predittiva sui Macchinari",
      description:
        "Implementiamo soluzioni per la manutenzione predittiva, riducendo i tempi di fermo e aumentando la durata dei Vostri macchinari. Sfruttando algoritmi di Machine Learning e Data Analysis, ti mostriamo lo stato dei macchinari in maniera chiara ed efficiente",
      icon: "🔧",
    },
    {
      title: "Digitalizzazione del Plant Aziendale",
      description:
        "Trasformiamo gli impianti fisici in ambienti digitali per la raccolta e l'analisi dei dati, per fornivi una visione innovativa e più approfondita sulle vostre linee",
      icon: "💻",
    },
    {
      title: "Simulation Environment",
      description:
        "Creiamo ambienti di simulazione parametrica per ottimizzare i Vostri processi industriali e ridurre gli errori. La rappresentazione fedele del macchinario non è più un nice to have, ma diventa una vera e copia su cui agire attivamente",
      icon: "⚙️",
    },
    {
      title: "Virtual Control",
      description:
        "Creiamo soluzioni sicure per il controllo remoto della produzione, per interventi a distanza su componenti cost-saving non critici.",
      icon: "🖥️",
    },
    {
      title: "Sviluppiamo Digital Twin",
      description:
        "Creiamo i modelli digitali dei tuoi impianti, con l'obbiettivo di simulare, monitorare e ottimizzare i processi industriali. Per noi il Digital Twin non è un concetto di marketing, ma uno strumento innovativo per fornire un vantaggio competitivo alla vostra azienda",
      icon: "🏭",
    },
  ];

  const services = [
    "Digital Twin",
    "Real Time Monitoring per Linee di Produzione",
    "Virtual Commissioning per Automazione Industriale",
    "Manutenzione Predittiva per Macchinari Industriali",
    "Simulation Environment per Processi Produttivi",
  ];

  const data = [
    {
      id: 1, // Unique ID for each item
      title: "Cerchi un partner per la digitalizzazione dei tuoi processi?",
      description:
        // QUESTO TESTO E' PIU' GIUSTO COME SLOGAN CHE COME PRIMO STEP DELLA TIMELINE "Offriamo soluzioni integrate per la digitalizzazione completa del tuo impianto. Attraverso la connessione diretta ai sistemi di controllo, ti permettiamo di monitorare, analizzare e ottimizzare in tempo reale ogni fase della produzione. Con il nostro supporto, il tuo impianto diventa un ecosistema digitale perfettamente sincronizzato.",
        "Il primo step è la mappatura del tuo impianto. Raccogliamo i dati grazie al nostro hardware Gemini e ti forniamo una dashboard intuitiva per avere tutto il plant sotto controllo.",
      buttonText: "Scopri come",
      buttonLink: "/real-time-monitoring",
      photo: "logo.png", // Modello GLB per l'assembly line
    },
    {
      id: 2, // Unique ID for each item
      title: "Vuoi approfondire il monitoraggio dei tuoi processi?",
      description:
        // "Progettiamo soluzioni personalizzate per il monitoraggio avanzato dei tuoi processi industriali. Grazie all'integrazione di sensori e dispositivi hardware, raccogliamo dati in tempo reale dai tuoi impianti e forniamo dashboard intuitive per un controllo completo e un'analisi predittiva accurata.",
        "I tuoi macchinari dicono molto più di quanto pensi, e visualizzare lo stato dell'impianto attraverso il Real-Time Monitoring è uno step necessario per la realizzazione di un'azienda digitale",
      buttonText: "Scopri di più",
      buttonLink: "/real-time-monitoring",
      photo: "logo.png", // Lo stesso modello GLB
    },
    {
      id: 3, // Unique ID for each item
      title:
        "Vuoi fare delle simulazioni parametriche per testare la produzione dei tuoi impianti?",
      description:
        "Mettiamo a tua disposizione ambienti di simulazione avanzata per testare parametri produttivi e ottimizzare i processi prima della loro implementazione. Le nostre soluzioni ti permettono di anticipare problemi e inefficienze, migliorando la produttività e riducendo il rischio di errori costosi.",
      buttonText: "Approfondisci",
      buttonLink: "/simulazioni",
      photo: "logo.png", // Lo stesso modello GLB
    },
    {
      id: 4, // Unique ID for each item
      title:
        "Vuoi gestire la manutenzione del tuo impianto in maniera innovativa?",
      description:
        "Le nostre soluzioni di manutenzione predittiva ti aiutano a programmare interventi mirati, riducendo i tempi di fermo e aumentando l'affidabilità dei tuoi impianti. Grazie ai dati raccolti in tempo reale, possiamo prevedere e prevenire guasti, mantenendo i tuoi macchinari sempre operativi.",
      buttonText: "Scopri come",
      buttonLink: "/manutenzione-predittiva",
      photo: "logo.png", // Lo stesso modello GLB
    },
    {
      id: 5, // Unique ID for each item
      title: "Vuoi un ambiente di test per l'automazione elettro-meccanica?",
      description:
        "Offriamo soluzioni di virtual commissioning, consentendoti di testare una nuova automazione, sia in fase progettuale che di programmazione del PLC e dei sistemi di controllo prima della realizzazione fisica della macchina. Questo approccio riduce gli errori grossolani e accelera il processo di messa in funzione e realizzazione della linea, garantendo una transizione più fluida e senza imprevisti.",
      buttonText: "Scopri di più",
      buttonLink: "/virtual-commissioning",
      photo: "logo.png", // Lo stesso modello GLB
    },
    {
      id: 6, // Unique ID for each item
      title: "Vuoi monitorare l'OEE?",
      description:
        "Gli indici di efficientamento energetici sono fondamentali per le aziende del futuro, applica ai tuoi macchinari i nostri avanzati sistemi di analisi e prendi le giuste decisioni sulla tua azienda.",
      buttonText: "Scopri di più",
      buttonLink: "/OEE-boost",
      photo: "logo.png", // Lo stesso modello GLB
    },
    {
      id: 7, // Unique ID for each item
      title: "Vuoi lavorare nel futuro?",
      description:
        "Con le nostre soluzioni, ti permettiamo di controllare il tuo impianto a distanza, agire su operazioni cost-saving non critiche, mantenendo la sicurezza e l'affidabilità che ci contraddistingue",
      buttonText: "Scopri di più",
      buttonLink: "/virtual-control",
      photo: "logo.png", // Lo stesso modello GLB
    },
  ];

  const [currentService, setCurrentService] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsIntroScrolled(true);
    }, 10000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      {/* {!showIntro && <Navbar />}
      {showIntro && (
        <section className="intro-screen">
          <h1 className="intro-text">
            Gemini automation |{" "}
            <ServiceCarousel
              currentService={currentService}
              services={services}
            />
          </h1>
        </section>
      )} */}

      {!showIntro && <Navbar />}
      {/* Intro Screen */}
      {showIntro && (
        <section className="intro-screen">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80%",
              padding: "10px", // Aggiunto per evitare overflow su schermi piccoli
            }}
          >
            <video
              ref={videoRef}
              className="intro-video"
              src="/intro_gemini.mov"
              autoPlay
              muted
              playsInline
              style={{
                maxWidth: "100%", // Limita la larghezza del video a quella dello schermo
                maxHeight: "100%", // Evita che il video esca dal contenitore
                borderRadius: "8px", // Aggiunge un tocco estetico
              }}
            ></video>
          </div>
        </section>
      )}
      <div>
        <Animation
          videoSrc="/AnimationPLC.mp4"
          videoAlt="Video dimostrativo"
          autoplay={true}
          loop={true}
          muted={true}
          controls={false}
          theme="dark"
        />
      </div>
      <section className={`home-page ${showIntro ? "" : "show"}`}>
        <div className="home-content">
          {/* <Notification
            text="Creando il futuro dell'ingegneria"
            icon={<FaRegClock />}
            onClick={(status) => console.log("Status attivo:", status)}
          /> */}
          <div
            style={{ marginTop: "20vh", height: "100vh", width: "100%" }}
            className="dynamic-text-container"
          >
            <p className="corsivo-elegante">Chi siamo</p>
            <DynamicText />
          </div>

          <div class="header-container">
            <p class="corsivo-elegante">Cosa facciamo</p>
            <h1>
              Modellazione, Implementazione e Distribuzione di Digital Twin in
              Ambienti Cyber-Fisici
            </h1>
            <h6>
              Soluzioni di Digitalizzazione avanzate. Grazie all'integrazione di
              sensori, IoT e piattaforme innovative, ottieni un controllo remoto
              completo e in tempo reale dei tuoi sistemi. Qualsiasi cosa abbia
              un sensore, noi la digitalizziamo.
            </h6>
          </div>

          <br></br>
          <br></br>
          <br></br>
          <div style={{ marginTop: "20vh", height: "100%", width: "100%" }}>
            <Animation
              videoSrc="/services.mov"
              videoAlt="Servizi"
              autoplay={true}
              loop={true}
              muted={true}
              controls={false}
              theme="dark"
              backgroundColor="rgba(255, 255, 255, 1)"
            />
          </div>
          {/* <div
            style={{
              height: "50vh",
              overflow: "hidden", // Disabilita lo scroll
              pointerEvents: "none", // Disabilita le interazioni dell'utente
              top: "0", // Puoi personalizzare la posizione verticale come preferisci
              left: "0", // Puoi personalizzare la posizione orizzontale come preferisci
              width: "100%", // Imposta la larghezza della div al 100% dello schermo
              zIndex: 10, // Puoi modificare il valore di zIndex per gestire il layering rispetto ad altri elementi
            }}
          >
            <ThreeDModel modelPath="/3D/stazione4.gltf" />
          </div> */}

          {/* <Photo imageSrc="/cyber-fisical.png" imageAlt="Gemini Automation" /> */}
        </div>

        {/* <AnimationGemini /> */}
        {/* <div style={{ marginTop: "10vh", height: "70%" }}>
          <Animation
            videoSrc="/notifica_gemini_remix.mov"
            videoAlt="notified"
            autoplay={true}
            loop={true}
            muted={true}
            controls={false}
            theme="light"
            backgroundColor="rgba(255, 255, 255, 1)"
          />
        </div> */}
      </section>
      {/* <div className={`home-page ${showIntro ? "" : "show"}`}>
        <div style={{ backgroundColor: "#c2c3bf", padding: "10vh" }}>
          <h1
            style={{
              textAlign: "center",
              marginBottom: "10vh",
              marginTop: "10vh",
            }}
          >
            Un concetto complesso, reso semplice.
          </h1>
          <MagnifyingGlass />
        </div>
      </div> */}
      {/* {!showIntro && (
        <section className="gemello-digitale">
          <Modello3d modelPath="/assembly-line.glb" />
        </section>
      )} */}
      {!showIntro && <Footer />}
    </>
  );
};

export default HomePage;
